/*css -scss/less */
.g-page {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    vertical-align: middle;
    margin: 10px 0;
    font-size: 0;

    li{
        display: inline-block;
        *display: inline;
        *zoom: 1;
        vertical-align: middle;
        padding: 12px 15px;
        height: 48px;
        line-height: 28px;
        margin: 0 -1px 5px 0;
        background-color: #fff;
        color: #333;
        font-size: 12px;
        border: 1px solid #e2e2e2;
        cursor: pointer;
        &:hover{
            color: #009688;
        }
    }
    
}

.activePage{
    background-color: #009688;
    color: #fff;
    &:hover{
        color: #000;
    }
}