@import '../../App.scss';
.login_logo{
    position: relative;
    background-color: $page_background_color;
    height: 100vh;
    color: #fff;
    .layout{
        border-radius: 15px;
    }

    .login-form-control:focus {
        box-shadow: none;
    }
}