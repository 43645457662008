body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.toast-it {
  background-color: #555555;
  color: #F4F4F4;
  padding: 3px 20px;
  border-radius: 20px;
  text-align: center;
  position: fixed;
  /* center horizontally */
  top: 80%;
  left: 50%;
  transform: translate(-50%, -80%);
  -webkit-animation-name: TOAST-APPEAR;
          animation-name: TOAST-APPEAR;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  -webkit-animation-duration: 3s;
          animation-duration: 3s; }

@-webkit-keyframes TOAST-APPEAR {
  0% {
    opacity: 0; }
  15% {
    opacity: 1; }
  80% {
    opacity: 1;
    top: 80%; }
  100% {
    opacity: 0;
    top: 75%; } }

@keyframes TOAST-APPEAR {
  0% {
    opacity: 0; }
  15% {
    opacity: 1; }
  80% {
    opacity: 1;
    top: 80%; }
  100% {
    opacity: 0;
    top: 75%; } }

.loader {
  background-color: rgba(0, 0, 0, 0.3);
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99999; }

.loader-inner {
  bottom: 0;
  height: 60px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 100px; }

.loader-line-wrap {
  -webkit-animation: spin 2000ms cubic-bezier(0.175, 0.885, 0.32, 1.275) infinite;
          animation: spin 2000ms cubic-bezier(0.175, 0.885, 0.32, 1.275) infinite;
  box-sizing: border-box;
  height: 50px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  transform-origin: 50% 100%;
  width: 100px; }

.loader-line {
  border: 4px solid transparent;
  border-radius: 100%;
  box-sizing: border-box;
  height: 100px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 100px; }

.loader-line-wrap:nth-child(1) {
  -webkit-animation-delay: -50ms;
          animation-delay: -50ms; }

.loader-line-wrap:nth-child(2) {
  -webkit-animation-delay: -100ms;
          animation-delay: -100ms; }

.loader-line-wrap:nth-child(3) {
  -webkit-animation-delay: -150ms;
          animation-delay: -150ms; }

.loader-line-wrap:nth-child(4) {
  -webkit-animation-delay: -200ms;
          animation-delay: -200ms; }

.loader-line-wrap:nth-child(5) {
  -webkit-animation-delay: -250ms;
          animation-delay: -250ms; }

.loader-line-wrap:nth-child(1) .loader-line {
  border-color: #eb4747;
  height: 90px;
  width: 90px;
  top: 7px; }

.loader-line-wrap:nth-child(2) .loader-line {
  border-color: #ebeb47;
  height: 76px;
  width: 76px;
  top: 14px; }

.loader-line-wrap:nth-child(3) .loader-line {
  border-color: #47eb47;
  height: 62px;
  width: 62px;
  top: 21px; }

.loader-line-wrap:nth-child(4) .loader-line {
  border-color: #47ebeb;
  height: 48px;
  width: 48px;
  top: 28px; }

.loader-line-wrap:nth-child(5) .loader-line {
  border-color: #4747eb;
  height: 34px;
  width: 34px;
  top: 35px; }

@-webkit-keyframes spin {
  0%, 15% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@keyframes spin {
  0%, 15% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

.nf-bg {
  background-color: #fc4c53; }

.nf-color {
  color: #999999; }

.nf-color-news-flash {
  color: #fc4c53; }

/*css -scss/less */
.g-page {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  vertical-align: middle;
  margin: 10px 0;
  font-size: 0; }
  .g-page li {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    vertical-align: middle;
    padding: 12px 15px;
    height: 48px;
    line-height: 28px;
    margin: 0 -1px 5px 0;
    background-color: #fff;
    color: #333;
    font-size: 12px;
    border: 1px solid #e2e2e2;
    cursor: pointer; }
    .g-page li:hover {
      color: #009688; }

.activePage {
  background-color: #009688;
  color: #fff; }
  .activePage:hover {
    color: #000; }

.vm-modal {
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.4); }

.main {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden; }

.cursor {
  cursor: pointer; }

.main_nav {
  width: 250px;
  min-width: 250px;
  height: 100%;
  background-color: #222c3a;
  color: #f8f9fa; }
  .main_nav .logo {
    width: 100%;
    height: 60px;
    font-weight: bold; }
  .main_nav .sub_item:hover {
    background-color: #007bff; }

.main_content {
  width: 100%;
  height: 100%; }
  .main_content .user {
    width: 100%;
    height: 60px; }

.login_logo {
  position: relative;
  background-color: #222c3a;
  height: 100vh;
  color: #fff; }
  .login_logo .layout {
    border-radius: 15px; }
  .login_logo .login-form-control:focus {
    box-shadow: none; }


/* animation */
.a-bounce,.a-flip,.a-flash,.a-shake,.a-swing,.a-wobble,.a-ring{-webkit-animation:1s ease;animation:1s ease;}
.a-fadein,.a-fadeinT,.a-fadeinR,.a-fadeinB,.a-fadeinL,.a-bouncein,.a-bounceinT,.a-bounceinR,.a-bounceinB,.a-bounceinL,.a-rotatein,.a-rotateinLT,.a-rotateinLB,.a-rotateinRT,.a-rotateinRB,.a-flipin,.a-flipinX,.a-flipinY{-webkit-animation:1s ease-out backwards;animation:1s ease-out backwards;}
.a-fadeout,.a-fadeoutT,.a-fadeoutR,.a-fadeoutB,.a-fadeoutL,.a-bounceout,.a-bounceoutT,.a-bounceoutR,.a-bounceoutB,.a-bounceoutL,.a-rotateout,.a-rotateoutLT,.a-rotateoutLB,.a-rotateoutRT,.a-rotateoutRB,.a-flipout,.a-flipoutX,.a-flipoutY{-webkit-animation:1s ease-in forwards;animation:1s ease-in forwards;}
/* 淡入 */
.a-fadein{-webkit-animation-name:fadein;animation-name:fadein;}
/* 淡入-从上 */
.a-fadeinT{-webkit-animation-name:fadeinT;animation-name:fadeinT;}
/* 淡入-从右 */
.a-fadeinR{-webkit-animation-name:fadeinR;animation-name:fadeinR;}
/* 淡入-从下 */
.a-fadeinB{-webkit-animation-name:fadeinB;animation-name:fadeinB;}
/* 淡入-从左 */
.a-fadeinL{-webkit-animation-name:fadeinL;animation-name:fadeinL;}
/* 淡出 */
.a-fadeout{-webkit-animation-name:fadeout;animation-name:fadeout;}
/* 淡出-向上 */
.a-fadeoutT{-webkit-animation-name:fadeoutT;animation-name:fadeoutT;}
/* 淡出-向右 */
.a-fadeoutR{-webkit-animation-name:fadeoutR;animation-name:fadeoutR;}
/* 淡出-向下 */
.a-fadeoutB{-webkit-animation-name:fadeoutB;animation-name:fadeoutB;}
/* 淡出-向左 */
.a-fadeoutL{-webkit-animation-name:fadeoutL;animation-name:fadeoutL;}
/* 弹跳 */
.a-bounce{-webkit-animation-name:bounce;animation-name:bounce;}
/* 弹入 */
.a-bouncein{-webkit-animation-name:bouncein;animation-name:bouncein;}
/* 弹入-从上 */
.a-bounceinT{-webkit-animation-name:bounceinT;animation-name:bounceinT;}
/* 弹入-从右 */
.a-bounceinR{-webkit-animation-name:bounceinR;animation-name:bounceinR;}
/* 弹入-从下 */
.a-bounceinB{-webkit-animation-name:bounceinB;animation-name:bounceinB;}
/* 弹入-从左 */
.a-bounceinL{-webkit-animation-name:bounceinL;animation-name:bounceinL;}
/* 弹出 */
.a-bounceout{-webkit-animation-name:bounceout;animation-name:bounceout;}
/* 弹出-向上 */
.a-bounceoutT{-webkit-animation-name:bounceoutT;animation-name:bounceoutT;}
/* 弹出-向右 */
.a-bounceoutR{-webkit-animation-name:bounceoutR;animation-name:bounceoutR;}
/* 弹出-向下 */
.a-bounceoutB{-webkit-animation-name:bounceoutB;animation-name:bounceoutB;}
/* 弹出-向左 */
.a-bounceoutL{-webkit-animation-name:bounceoutL;animation-name:bounceoutL;}
/* 转入 */
.a-rotatein{-webkit-animation-name:rotatein;animation-name:rotatein;}
/* 转入-从左上 */
.a-rotateinLT{-webkit-animation-name:rotateinLT;animation-name:rotateinLT;}
/* 转入-从左下 */
.a-rotateinLB{-webkit-animation-name:rotateinLB;animation-name:rotateinLB;}
/* 转入-从右上 */
.a-rotateinRT{-webkit-animation-name:rotateinRT;animation-name:rotateinRT;}
/* 转入-从右下*/
.a-rotateinRB{-webkit-animation-name:rotateinRB;animation-name:rotateinRB;}
/* 转出 */
.a-rotateout{-webkit-animation-name:rotateout;animation-name:rotateout;}
/* 转出-向左上 */
.a-rotateoutLT{-webkit-animation-name:rotateoutLT;animation-name:rotateoutLT;}
/* 转出-向左下 */
.a-rotateoutLB{-webkit-animation-name:rotateoutLB;animation-name:rotateoutLB;}
/* 转出-向右上 */
.a-rotateoutRT{-webkit-animation-name:rotateoutRT;animation-name:rotateoutRT;}
/* 转出-向右下 */
.a-rotateoutRB{-webkit-animation-name:rotateoutRB;animation-name:rotateoutRB;}
/* 翻转 */
.a-flip{-webkit-animation-name:flip;animation-name:flip;}
/* 翻入-X轴 */
.a-flipinX{-webkit-animation-name:flipinX;animation-name:flipinX;}
/* 翻入-Y轴 */
.a-flipin,.a-flipinY{-webkit-animation-name:flipinY;animation-name:flipinY;}
/* 翻出-X轴 */
.a-flipoutX{-webkit-animation-name:flipoutX;animation-name:flipoutX;}
/* 翻出-Y轴 */
.a-flipout,.a-flipoutY{-webkit-animation-name:flipoutY;animation-name:flipoutY;}
/* 闪烁 */
.a-flash{-webkit-animation-name:flash;animation-name:flash;}
/* 震颤 */
.a-shake{-webkit-animation-name:shake;animation-name:shake;}
/* 摇摆 */
.a-swing{-webkit-animation-name:swing;animation-name:swing;}
/* 摇晃 */
.a-wobble{-webkit-animation-name:wobble;animation-name:wobble;}
/* 震铃 */
.a-ring{-webkit-animation-name:ring;animation-name:ring;}
/* define */
/* 淡入 */
@-webkit-keyframes fadein{
    0%{opacity:0;}
    100%{opacity:1;}
}
@keyframes fadein{
    0%{opacity:0;}
    100%{opacity:1;}
}
/* 淡入-从上 */
@-webkit-keyframes fadeinT{
    0%{opacity:0;-webkit-transform:translateY(-100px);}
    100%{opacity:1;-webkit-transform:translateY(0);}
}
@keyframes fadeinT{
    0%{opacity:0;transform:translateY(-100px);}
    100%{opacity:1;transform:translateY(0);}
}
/* 淡入-从右 */
@-webkit-keyframes fadeinR{
    0%{opacity:0;-webkit-transform:translateX(100px);}
    100%{opacity:1;-webkit-transform:translateX(0);}
}
@keyframes fadeinR{
    0%{opacity:0;transform:translateX(100px);}
    100%{opacity:1;transform:translateX(0);}
}
/* 淡入-从下 */
@-webkit-keyframes fadeinB{
    0%{opacity:0;-webkit-transform:translateY(100px);}
    100%{opacity:1;-webkit-transform:translateY(0);}
}
@keyframes fadeinB{
    0%{opacity:0;transform:translateY(100px);}
    100%{opacity:1;transform:translateY(0);}
}
/* 淡入-从左 */
@-webkit-keyframes fadeinL{
    0%{opacity:0;-webkit-transform:translateX(-100px);}
    100%{opacity:1;-webkit-transform:translateX(0);}
}
@keyframes fadeinL{
    0%{opacity:0;transform:translateX(-100px);}
    100%{opacity:1;transform:translateX(0);}
}
/* 淡出 */
@-webkit-keyframes fadeout{
    0%{opacity:1;}
    100%{opacity:0;}
}
@keyframes fadeout{
    0%{opacity:1;}
    100%{opacity:0;}
}
/* 淡出-向上 */
@-webkit-keyframes fadeoutT{
    0%{opacity:1;-webkit-transform:translateY(0);}
    100%{opacity:0;-webkit-transform:translateY(-100px);}
}
@keyframes fadeoutT{
    0%{opacity:1;transform:translateY(0);}
    100%{opacity:0;transform:translateY(-100px);}
}
/* 淡出-向右 */
@-webkit-keyframes fadeoutR{
    0%{opacity:1;-webkit-transform:translateX(0);}
    100%{opacity:0;-webkit-transform:translateX(100px);}
}
@keyframes fadeoutR{
    0%{opacity:1;transform:translateX(0);}
    100%{opacity:0;transform:translateX(100px);}
}
/* 淡出-向下 */
@-webkit-keyframes fadeoutB{
    0%{opacity:1;-webkit-transform:translateY(0);}
    100%{opacity:0;-webkit-transform:translateY(100px);}
}
@keyframes fadeoutB{
    0%{opacity:1;transform:translateY(0);}
    100%{opacity:0;transform:translateY(100px);}
}
/* 淡出-向左 */
@-webkit-keyframes fadeoutL{
    0%{opacity:1;-webkit-transform:translateX(0);}
    100%{opacity:0;-webkit-transform:translateX(-100px);}
}
@keyframes fadeoutL{
    0%{opacity:1;transform:translateX(0);}
    100%{opacity:0;transform:translateX(-100px);}
}
/* 弹跳 */
@-webkit-keyframes bounce{
    0%,20%,50%,80%,100%{-webkit-transform:translateY(0);}
    40%{-webkit-transform:translateY(-30px);}
    60%{-webkit-transform:translateY(-15px);}
}
@keyframes bounce{
    0%,20%,50%,80%,100%{transform:translateY(0);}
    40%{transform:translateY(-30px);}
    60%{transform:translateY(-15px);}
}
/* 弹入 */
@-webkit-keyframes bouncein{
    0%{opacity:0;-webkit-transform:scale(0.3);}
    50%{opacity:1;-webkit-transform:scale(1.05);}
    70%{-webkit-transform:scale(0.9);}
    100%{-webkit-transform:scale(1);}
}
@keyframes bouncein{
    0%{opacity:0;transform:scale(0.3);}
    50%{opacity:1;transform:scale(1.05);}
    70%{transform:scale(0.9);}
    100%{transform:scale(1);}
}
/* 弹入-从上 */
@-webkit-keyframes bounceinT{
    0%{opacity:0;-webkit-transform:translateY(-100px);}
    60%{opacity:1;-webkit-transform:translateY(30px);}
    80%{-webkit-transform:translateY(-10px);}
    100%{-webkit-transform:translateY(0);}
}
@keyframes bounceinT{
    0%{opacity:0;transform:translateY(-100px);}
    60%{opacity:1;transform:translateY(30px);}
    80%{transform:translateY(-10px);}
    100%{transform:translateY(0);}
}
/* 弹入-从右 */
@-webkit-keyframes bounceinR{
    0%{opacity:0;-webkit-transform:translateX(100px);}
    60%{opacity:1;-webkit-transform:translateX(-30px);}
    80%{-webkit-transform:translateX(10px);}
    100%{-webkit-transform:translateX(0);}
}
@keyframes bounceinR{
    0%{opacity:0;transform:translateX(100px);}
    60%{opacity:1;transform:translateX(-30px);}
    80%{transform:translateX(10px);}
    100%{transform:translateX(0);}
}
/* 弹入-从下 */
@-webkit-keyframes bounceinB{
    0%{opacity:0;-webkit-transform:translateY(100px);}
    60%{opacity:1;-webkit-transform:translateY(-30px);}
    80%{-webkit-transform:translateY(10px);}
    100%{-webkit-transform:translateY(0);}
}
@keyframes bounceinB{
    0%{opacity:0;transform:translateY(100px);}
    60%{opacity:1;transform:translateY(-30px);}
    80%{transform:translateY(10px);}
    100%{transform:translateY(0);}
}
/* 弹入-从左 */
@-webkit-keyframes bounceinL{
    0%{opacity:0;-webkit-transform:translateX(-100px);}
    60%{opacity:1;-webkit-transform:translateX(30px);}
    80%{-webkit-transform:translateX(-10px);}
    100%{-webkit-transform:translateX(0);}
}
@keyframes bounceinL{
    0%{opacity:0;transform:translateX(-100px);}
    60%{opacity:1;transform:translateX(30px);}
    80%{transform:translateX(-10px);}
    100%{transform:translateX(0);}
}
/* 弹出 */
@-webkit-keyframes bounceout{
    0%{-webkit-transform:scale(1);}
    25%{-webkit-transform:scale(0.95);}
    50%{opacity:1;-webkit-transform:scale(1.1);}
    100%{opacity:0;-webkit-transform:scale(0.3);}
}
@keyframes bounceout{
    0%{transform:scale(1);}
    25%{transform:scale(0.95);}
    50%{opacity:1;transform:scale(1.1);}
    100%{opacity:0;transform:scale(0.3);}
}
/* 弹出-向上*/
@-webkit-keyframes bounceoutT{
    0%{-webkit-transform:translateY(0);}
    20%{opacity:1;-webkit-transform:translateY(20px);}
    100%{opacity:0;-webkit-transform:translateY(-100px);}
}
@keyframes bounceoutT{
    0%{transform:translateY(0);}
    20%{opacity:1;transform:translateY(20px);}
    100%{opacity:0;transform:translateY(-100px);}
}
/* 弹出-向右*/
@-webkit-keyframes bounceoutR{
    0%{-webkit-transform:translateX(0);}
    20%{opacity:1;-webkit-transform:translateX(-20px);}
    100%{opacity:0;-webkit-transform:translateX(100px);}
}
@keyframes bounceoutR{
    0%{transform:translateX(0);}
    20%{opacity:1;transform:translateX(-20px);}
    100%{opacity:0;transform:translateX(100px);}
}
/* 弹出-向下 */
@-webkit-keyframes bounceoutB{
    0%{-webkit-transform:translateY(0);}
    20%{opacity:1;-webkit-transform:translateY(-20px);}
    100%{opacity:0;-webkit-transform:translateY(100px);}
}
@keyframes bounceoutB{
    0%{transform:translateY(0);}
    20%{opacity:1;transform:translateY(-20px);}
    100%{opacity:0;transform:translateY(100px);}
}
/* 弹出-向左 */
@-webkit-keyframes bounceoutL{
    0%{-webkit-transform:translateX(0);}
    20%{opacity:1;-webkit-transform:translateX(20px);}
    100%{opacity:0;-webkit-transform:translateX(-100px);}
}
@keyframes bounceoutL{
    0%{transform:translateX(0);}
    20%{opacity:1;transform:translateX(20px);}
    100%{opacity:0;transform:translateX(-200px);}
}
/* 转入 */
@-webkit-keyframes rotatein{
    0%{opacity:0;-webkit-transform:rotate(-200deg);}
    100%{opacity:1;-webkit-transform:rotate(0);}
}
@keyframes rotatein{
    0%{opacity:0;transform:rotate(-200deg);}
    100%{opacity:1;transform:rotate(0);}
}
/* 转入-从左上 */
@-webkit-keyframes rotateinLT{
    0%{-webkit-transform-origin:left bottom;-webkit-transform:rotate(-90deg);opacity:0;}
    100%{-webkit-transform-origin:left bottom;-webkit-transform:rotate(0);opacity:1;}
}
@keyframes rotateinLT{
    0%{transform-origin:left bottom;transform:rotate(-90deg);opacity:0;}
    100%{transform-origin:left bottom;transform:rotate(0);opacity:1;}
}
/* 转入-从左下 */
@-webkit-keyframes rotateineftB{
    0%{-webkit-transform-origin:left bottom;-webkit-transform:rotate(90deg);opacity:0;}
    100%{-webkit-transform-origin:left bottom;-webkit-transform:rotate(0);opacity:1;}
}
@keyframes rotateineftB{
    0%{transform-origin:left bottom;transform:rotate(90deg);opacity:0;}
    100%{transform-origin:left bottom;transform:rotate(0);opacity:1;}
}
/* 转入-从右上 */
@-webkit-keyframes rotateinRT{
    0%{-webkit-transform-origin:right bottom;-webkit-transform:rotate(90deg);opacity:0;}
    100%{-webkit-transform-origin:right bottom;-webkit-transform:rotate(0);opacity:1;}
}
@keyframes rotateinRT{
    0%{transform-origin:right bottom;transform:rotate(90deg);opacity:0;}
    100%{transform-origin:right bottom;transform:rotate(0);opacity:1;}
}
/* 转入-从右下*/
@-webkit-keyframes rotateinRB{
    0%{-webkit-transform-origin:right bottom;-webkit-transform:rotate(-90deg);opacity:0;}
    100%{-webkit-transform-origin:right bottom;-webkit-transform:rotate(0);opacity:1;}
}
@keyframes rotateinRB{
    0%{transform-origin:right bottom;transform:rotate(-90deg);opacity:0;}
    100%{transform-origin:right bottom;transform:rotate(0);opacity:1;}
}
/* 转出 */
@-webkit-keyframes rotateout{
    0%{-webkit-transform-origin:center center;-webkit-transform:rotate(0);opacity:1;}
    100%{-webkit-transform-origin:center center;-webkit-transform:rotate(200deg);opacity:0;}
}
@keyframes rotateout{
    0%{transform-origin:center center;transform:rotate(0);opacity:1;}
    100%{transform-origin:center center;transform:rotate(200deg);opacity:0;}
}
/* 转出-向左上 */
@-webkit-keyframes rotateoutLT{
    0%{-webkit-transform-origin:left bottom;-webkit-transform:rotate(0);opacity:1;}
    100%{-webkit-transform-origin:left bottom;-webkit-transform:rotate(-90deg);opacity:0;}
}
@keyframes rotateoutLT{
    0%{transform-origin:left bottom;transform:rotate(0);opacity:1;}
    100%{transform-origin:left bottom;transform:rotate(-90deg);opacity:0;}
}
/* 转出-向左下 */
@-webkit-keyframes rotateoutLB{
    0%{-webkit-transform-origin:left bottom;-webkit-transform:rotate(0);opacity:1;}
    100%{-webkit-transform-origin:left bottom;-webkit-transform:rotate(90deg);opacity:0;}
}
@keyframes rotateoutLB{
    0%{transform-origin:left bottom;transform:rotate(0);opacity:1;}
    100%{transform-origin:left bottom;transform:rotate(90deg);opacity:0;}
}
/* 转出-向右上 */
@-webkit-keyframes rotateoutRT{
    0%{-webkit-transform-origin:right bottom;-webkit-transform:rotate(0);opacity:1;}
    100%{-webkit-transform-origin:right bottom;-webkit-transform:rotate(90deg);opacity:0;}
}
@keyframes rotateoutRT{
    0%{transform-origin:right bottom;transform:rotate(0);opacity:1;}
    100%{transform-origin:right bottom;transform:rotate(90deg);opacity:0;}
}
/* 转出-向右下 */
@-webkit-keyframes rotateoutBR{
    0%{-webkit-transform-origin:right bottom;-webkit-transform:rotate(0);opacity:1;}
    100%{-webkit-transform-origin:right bottom;-webkit-transform:rotate(-90deg);opacity:0;}
}
@keyframes rotateoutBR{
    0%{transform-origin:right bottom;transform:rotate(0);opacity:1;}
    100%{transform-origin:right bottom;transform:rotate(-90deg);opacity:0;}
}
/* 翻转 */
@-webkit-keyframes flip{
    0%{-webkit-transform:perspective(400px) rotateY(0);-webkit-animation-timing-function:ease-out;}
    40%{-webkit-transform:perspective(400px) translateZ(150px) rotateY(170deg);-webkit-animation-timing-function:ease-out;}
    50%{-webkit-transform:perspective(400px) translateZ(150px) rotateY(190deg) scale(1);-webkit-animation-timing-function:ease-in;}
    80%{-webkit-transform:perspective(400px) rotateY(360deg) scale(0.95);-webkit-animation-timing-function:ease-in;}
    100%{-webkit-transform:perspective(400px) scale(1);-webkit-animation-timing-function:ease-in;}
}
@keyframes flip{
    0%{transform:perspective(400px) rotateY(0);-webkit-animation-timing-function:ease-out;animation-timing-function:ease-out;}
    40%{transform:perspective(400px) translateZ(150px) rotateY(170deg);-webkit-animation-timing-function:ease-out;animation-timing-function:ease-out;}
    50%{transform:perspective(400px) translateZ(150px) rotateY(190deg) scale(1);-webkit-animation-timing-function:ease-in;animation-timing-function:ease-in;}
    80%{transform:perspective(400px) rotateY(360deg) scale(0.95);-webkit-animation-timing-function:ease-in;animation-timing-function:ease-in;}
    100%{transform:perspective(400px) scale(1);-webkit-animation-timing-function:ease-in;animation-timing-function:ease-in;}
}
/* 翻入-X轴 */
@-webkit-keyframes flipinX{
    0%{-webkit-transform:perspective(400px) rotateX(90deg);opacity:0;}
    40%{-webkit-transform:perspective(400px) rotateX(-10deg);}
    70%{-webkit-transform:perspective(400px) rotateX(10deg);}
    100%{-webkit-transform:perspective(400px) rotateX(0);opacity:1;}
}
@keyframes flipinX{
    0%{transform:perspective(400px) rotateX(90deg);opacity:0;}
    40%{transform:perspective(400px) rotateX(-10deg);}
    70%{transform:perspective(400px) rotateX(10deg);}
    100%{transform:perspective(400px) rotateX(0);opacity:1;}
}
/* 翻入-Y轴 */
@-webkit-keyframes flipinY{
    0%{-webkit-transform:perspective(400px) rotateY(90deg);opacity:0;}
    40%{-webkit-transform:perspective(400px) rotateY(-10deg);}
    70%{-webkit-transform:perspective(400px) rotateY(10deg);}
    100%{-webkit-transform:perspective(400px) rotateY(0);opacity:1;}
}
@keyframes flipinY{
    0%{transform:perspective(400px) rotateY(90deg);opacity:0;}
    40%{transform:perspective(400px) rotateY(-10deg);}
    70%{transform:perspective(400px) rotateY(10deg);}
    100%{transform:perspective(400px) rotateY(0);opacity:1;}
}
/* 翻出-X轴 */
@-webkit-keyframes flipoutX{
    0%{-webkit-transform:perspective(400px) rotateX(0);opacity:1;}
    100%{-webkit-transform:perspective(400px) rotateX(90deg);opacity:0;}
}
@keyframes flipoutX{
    0%{transform:perspective(400px) rotateX(0);opacity:1;}
    100%{transform:perspective(400px) rotateX(90deg);opacity:0;}
}
/* 翻出-Y轴 */
@-webkit-keyframes flipoutY{
    0%{-webkit-transform:perspective(400px) rotateY(0);opacity:1;}
    100%{-webkit-transform:perspective(400px) rotateY(90deg);opacity:0;}
}
@keyframes flipoutY{
    0%{transform:perspective(400px) rotateY(0);opacity:1;}
    100%{transform:perspective(400px) rotateY(90deg);opacity:0;}
}
/* 闪烁 */
@-webkit-keyframes flash{
    0%,50%,100%{opacity:1;}
    25%,75%{opacity:0;}
}
@keyframes flash{
    0%,50%,100%{opacity:1;}
    25%,75%{opacity:0;}
}
/* 震颤 */
@-webkit-keyframes shake{
    0%,100%{-webkit-transform:translateX(0);}
    10%,30%,50%,70%,90%{-webkit-transform:translateX(-10px);}
    20%,40%,60%,80%{-webkit-transform:translateX(10px);}
}
@keyframes shake{
    0%,100%{transform:translateX(0);}
    10%,30%,50%,70%,90%{transform:translateX(-10px);}
    20%,40%,60%,80%{transform:translateX(10px);}
}
/* 摇摆 */
@-webkit-keyframes swing{
    20%{-webkit-transform:rotate(15deg);}
    40%{-webkit-transform:rotate(-10deg);}
    60%{-webkit-transform:rotate(5deg);}
    80%{-webkit-transform:rotate(-5deg);}
    100%{-webkit-transform:rotate(0);}
}
@keyframes swing{
    20%{transform:rotate(15deg);}
    40%{transform:rotate(-10deg);}
    60%{transform:rotate(5deg);}
    80%{transform:rotate(-5deg);}
    100%{transform:rotate(0);}
}
/* 摇晃 */
@-webkit-keyframes wobble{
    0%{-webkit-transform:translateX(0);}
    15%{-webkit-transform:translateX(-100px) rotate(-5deg);}
    30%{-webkit-transform:translateX(80px) rotate(3deg);}
    45%{-webkit-transform:translateX(-65px) rotate(-3deg);}
    60%{-webkit-transform:translateX(40px) rotate(2deg);}
    75%{-webkit-transform:translateX(-20px) rotate(-1deg);}
    100%{-webkit-transform:translateX(0);}
}
@keyframes wobble{
    0%{transform:translateX(0);}
    15%{transform:translateX(-100px) rotate(-5deg);}
    30%{transform:translateX(80px) rotate(3deg);}
    45%{transform:translateX(-65px) rotate(-3deg);}
    60%{transform:translateX(40px) rotate(2deg);}
    75%{transform:translateX(-20px) rotate(-1deg);}
    100%{transform:translateX(0);}
}
/* 震铃 */
@-webkit-keyframes ring{
    0%{-webkit-transform:scale(1);}
    10%,20%{-webkit-transform:scale(0.9) rotate(-3deg);}
    30%,50%,70%,90%{-webkit-transform:scale(1.1) rotate(3deg);}
    40%,60%,80%{-webkit-transform:scale(1.1) rotate(-3deg);}
    100%{-webkit-transform:scale(1) rotate(0);}
}
@keyframes ring{
    0%{transform:scale(1);}
    10%,20%{transform:scale(0.9) rotate(-3deg);}
    30%,50%,70%,90%{transform:scale(1.1) rotate(3deg);}
    40%,60%,80%{transform:scale(1.1) rotate(-3deg);}
    100%{transform:scale(1) rotate(0);}
}
