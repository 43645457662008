$newsflashColor: rgb(252, 76, 83);

.nf-bg{
   background-color: $newsflashColor; 
}

.nf-color{
   color: #999999;
}

.nf-color-news-flash{
   color: $newsflashColor;
}