@import '../../App.scss';
$nav_background: rgb(54, 63, 78);
$nav_logo_heigth: 60px;
.main{
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    
}
.cursor{
    cursor: pointer;
}
.main_nav{
    width: 250px;
    min-width: 250px;
    height: 100%;
    background-color: $page_background_color;
    color: #f8f9fa;
    .logo{
        width: 100%;
        height: $nav_logo_heigth;
        font-weight: bold;
    }
    .sub_item:hover{
        background-color: #007bff;
    }
}

.main_content{
    width: 100%;
    height: 100%;
    .user{
        width: 100%;
        height: $nav_logo_heigth;
    }
}